.loader_custom span {
    margin: auto;
    display: block;
}
.res-head .nav-item .nav-link svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
.nav-pills .nav-link{
    border-radius: 0;
}

.ViewModalContact .parent-category{
    display: none
}


.ViewModalContact input[type=checkbox]:checked ~ .parent-category{
    display: block
}

.btn-video-upload {
    transition: border-color .3s ease;
    border: 0.2em solid #000000;
    border-radius: 1em;
    text-align: center;
    padding: 3em 1em;
    cursor: pointer;
    font-size: 12px;
    width: 100%;
    font-weight: bold;
    font-family: 'Poppins',sans-serif;
    background: #fdfcfb;
}

.loader-container {
    height: 280px;
    padding: 4rem;
    background: black;
    border: 4px solid rgb(0, 114, 207);
    margin-bottom:2rem
}
.spin-loader h5 {
    color: white;
    font-size: 18px;
    margin-bottom: 1rem;
}
.spin-loader .mui-kud8pv {
    left: -10px !important;

}

.wrapper h5{
    color: black;
    font-size: 18px;
}

button.next-btn {
    border: none;
    text-transform: uppercase;
    padding: 6px 8px;
    font-size: 14px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
}
button.finish-btn {
    border: none;
    text-transform: uppercase;
    padding: 6px 8px;
    font-size: 14px;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
  float: right;
    letter-spacing: 0.02857em;
}
.draft-btn {
    margin-left: 1rem;
}

.btn:disabled {
    color: rgba(0, 0, 0, 0.26);
    border: none;
    opacity: 1;
}

button.back-btn {
    color: black;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    border: none;
}

button.back-btn:hover {
    color: black;
}
.test-heading{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 0;
    text-transform: capitalize;
}

button.btn-cross {
    border: 1px solid #ec1246;
    background: white;
    color: #ec1246;
    font-size: 13px;
    font-weight: 900;
    border-radius: 5px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.wrapup {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
hr.border-up {
    margin-top: 30px;
    opacity: 0.2;
}

.ViewTest p {
    font-family: "Poppins", sans-serif;
    color: #a0a0a0;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 14px;
}

span.vidEditBtn {
    position: absolute;
    top: 32px;
    right: 5px;
    background: #ec1246;
    border-radius: 50px;
    padding: 0px 0px;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
}


span.vidEditBtn svg {
    color: white;
}

.editplaypause {
    position: absolute;
    display: none;
    background: #00000091;
    padding: 5px 10px;
    padding: 5px 10px;
    top: 30%;
    right: 45%;
}

.editplaypause svg {
    color: white;
    font-size: 3rem;
}

.vidContainer:hover .editplaypause  {
    transition: 2s;
    display:block;

}

/*Delete popup customization*/
.swal-icon--success:before {
    top: -21px !important;
    left: -22px !important;
}
.swal-icon--success:after {
    top: -24px !important;
    left: 43px !important;
}
.swal-icon--success__hide-corners {

    left: 39px !important;
    top: -7px !important;

}
.swal-icon--success__ring {
    border: 4px solid hsl(98deg 55% 69%);
}
.filter {
    height: 40px;
}

input.PhoneInputInput {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    box-shadow: none!important;
    color: #212529;
    display: block;
    font-size: 12px;
    line-height: 18px;
    max-height: 32.9px;
    outline: none!important;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    min-height: 36px;
    position: relative;
    transition: all 100ms ease 0s;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: 0px !important;
}
.PhoneInputInput::placeholder {
    font-size: 12px;
    line-height: 18px;
    box-shadow: none !important;
    outline: none !important;
}
.PhoneInput--focus .PhoneInputInput{
    border: 1px solid #ced4da !important;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: none !important;
    outline: none !important;
}
.PhoneInput--focus:focus{
    box-shadow: none !important;
    outline: none !important;
}

a.user-link {
    text-decoration: none;
    color: black;
    font-weight: 600;
}

/*flip video class*/
.flip-video{
    transform: scale(-1, 1);
}

button.btn-expand {
    border: none;
    font-size: 10px;
    font-weight: 500;
    background: none;
    padding: 0;
}



@media (max-width: 1600px) {
    .SummaryCoverImg {
        max-height: 89px;
    }
}
@media (max-width: 1440px) {
    .SummaryCoverImg {
        max-height: 77px;
    }
}
@media (max-width: 1367px) {
    .SummaryCoverImg {
        max-height: 69px;
    }
}
@media (max-width: 1200px) {
    .SummaryCoverImg {
        max-height: 60px;
    }
}

@media (max-width: 991.98px) {
    .SummaryCoverImg {
        max-height: 110px;
    }
}
.spin-loader-screen {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #0000004a;
}

.video-player {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.video-control {
    position: absolute;
    border: unset;
    border-radius: 50%;
    padding: 3px 10px;
    color: white;
    background-color: #0000006e;
    display: none;
}

.video-player:hover .video-control{
    display: block;
}

.heading {
    text-align: center;
    background-color: #212529;
    color: white;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;

}
.border-round{
    border: 1px solid #212529;
}

.detail-img {border-left: 1px solid #212529;border-right: 1px solid #212528;border-bottom: 1px solid #212529;}

select.PhoneInputCountrySelect:disabled {
    opacity: 0 !important;
}

.custom-dropdown.show {
    display: block;
    position: unset;
    border: none;
    border-radius: 0;
    background-color: #f5deb300;
}

.dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #ec1246;
}

a.dropdown-item.active svg,  a.dropdown-item svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

a.dropdown-item.active svg path {
    fill: #ffffff;
}
.custom-dropdown li {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    background: transparent;
    border-radius: 0;
    color: #000000;
    padding-left: 5px;
    padding-right: 0;
}
.custom-toggle.dropdown-toggle::after{
    position: absolute;
    right: 10px;
    top: 15px;
}

span.nav-link.custom-toggle.dropdown-toggle {
    min-width: 195px;
    width: 100%;
}

.DraftEditor-editorContainer {
    background-color: #fff!important;
    border-left: 0.1px solid #ccc!important;
    min-height: 300px;
    padding: 0.375rem 0.75rem;
    max-height: 500px;
    overflow-x: auto;
}

.rdw-editor-toolbar {
    border: 1px solid #ccc;
}
.rdw-embedded-modal{
    height: 210px !important;
}

.btn-black-theme{
    background: #000000;
    border-radius: 0;
    border: 1px solid transparent;
    transition: all 0.5s ease;
    color: #fff;
    font-size: 13px;
}

.btn-black-theme:hover, .btn-black-theme:focus {
    background: #fff;
    color: #000000;
    border: 1px solid #000000;
    transition: all 0.5s ease;
}

textarea.codeEditor {
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    overflow-x: auto;
    padding: 0.375rem 0.75rem;
}